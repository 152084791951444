/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

html,
body {
  margin: 0;
  font-size: 10px;
  background-color: #fafafa;
}

.MuiInputLabel-root {
  font-size: 25px !important;
  padding-bottom: 20px !important;
}

.MuiInput-root {
  height: 45px !important;
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
/* //////////////// */
.Toastify__toast-body {
  color: #ffff;
  font-size: 16px;
  /* padding-left: 20px; */
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 15px !important;
}
.Toastify__toast > button > svg {
  display: none;
}
